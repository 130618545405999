import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "file",
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24"
}
const _hoisted_2 = {
  id: "icon",
  transform: "translate(-1364 -91)"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("rect", {
      id: "Rechteck_2443",
      "data-name": "Rechteck 2443",
      width: "24",
      height: "24",
      fill: "none"
    }, null, -1)),
    _createElementVNode("g", _hoisted_2, [
      _createElementVNode("path", {
        id: "outline3",
        d: "M1379,105h-6a1,1,0,0,1,0-2h6a1,1,0,0,1,0,2Z",
        class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
      }, null, 2),
      _createElementVNode("path", {
        id: "outline2",
        d: "M1379,109h-6a1,1,0,0,1,0-2h6a1,1,0,0,1,0,2Z",
        class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
      }, null, 2),
      _createElementVNode("path", {
        id: "outline1",
        d: "M1376.584,93H1370a2.006,2.006,0,0,0-2,2v16a2.006,2.006,0,0,0,2,2h12a2.006,2.006,0,0,0,2-2V100.414a1,1,0,0,0-.293-.707l-6.413-6.413A1,1,0,0,0,1376.584,93ZM1382,111h-12V95h7v3.5a1.5,1.5,0,0,0,1.5,1.5h3.5Z",
        class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
      }, null, 2)
    ])
  ]))
}