import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-primary" }
const _hoisted_2 = { class: "text-sm mt-3 mb-3" }
const _hoisted_3 = { class: "text-sm mb-3" }
const _hoisted_4 = { class: "border-t-2 border-grey-lightest pt-5" }
const _hoisted_5 = { class: "text-primary mb-5" }
const _hoisted_6 = { class: "w-full grid md:grid-cols-2 grid-cols-1 gap-5" }
const _hoisted_7 = { class: "flex justify-between mb-5 mt-5" }
const _hoisted_8 = { class: "text-grey-darkest" }
const _hoisted_9 = { class: "text-sm text-grey" }
const _hoisted_10 = { class: "w-full border-t-2 border-grey-lightest pt-5" }
const _hoisted_11 = { class: "text-primary mb-5" }
const _hoisted_12 = { class: "w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_input_default = _resolveComponent("input-default")!
  const _component_input_month = _resolveComponent("input-month")!
  const _component_input_switch = _resolveComponent("input-switch")!
  const _component_input_select = _resolveComponent("input-select")!
  const _component_centered_modal = _resolveComponent("centered-modal")!

  return (_openBlock(), _createBlock(_component_centered_modal, {
    onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('close'))),
    title: _ctx.$t('create_normlist'),
    cancelButtonText: _ctx.$t('cancel'),
    mainContainerId: 'customer_administration'
  }, {
    actionButton: _withCtx(() => [
      _createVNode(_component_custom_button, {
        id: 'generate-normlist',
        class: "max-w-full",
        isDisabled: _ctx.isMissingData,
        onClick: _ctx.generateCustomerNormList
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("create")), 1)
        ]),
        _: 1
      }, 8, ["isDisabled", "onClick"])
    ]),
    content: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t("customer")), 1),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("name")) + ": " + _toDisplayString(_ctx.user ? _ctx.user.customer?.name : "-"), 1),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("norms_count")) + ": " + _toDisplayString(_ctx.user ? _ctx.user.customer?.norms_count : "-"), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("settings.settings")), 1),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_input_default, {
            id: 'construction_type',
            name: 'construction_type',
            modelValue: _ctx.toEditData.construction_type,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.toEditData.construction_type) = $event)),
            label: _ctx.$t('normlist_construction_type'),
            class: "w-full"
          }, null, 8, ["modelValue", "label"]),
          _createVNode(_component_input_default, {
            id: 'type',
            name: 'type',
            modelValue: _ctx.toEditData.type,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.toEditData.type) = $event)),
            label: _ctx.$t('normlist_type'),
            class: "w-full"
          }, null, 8, ["modelValue", "label"]),
          _createVNode(_component_input_month, {
            class: "w-full",
            modelValue: _ctx.toEditData.date,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.toEditData.date) = $event)),
            onChangeMonth: _ctx.setDate
          }, null, 8, ["modelValue", "onChangeMonth"])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", null, [
            _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t("norm_collection")), 1),
            _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t("norm_collection_text")), 1)
          ]),
          _createVNode(_component_input_switch, {
            id: 'switch-change-basedata',
            name: 'switch-change-basedata',
            modelValue: _ctx.toEditData.include_rejected_norms,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.toEditData.include_rejected_norms) = $event)),
            tabIndex: 2,
            class: "mt-2"
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t("version")), 1),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_input_select, {
              id: "select-version",
              name: "select-version",
              label: _ctx.$t('normlist_format'),
              selectItemConfiguration: {
                                valueColumn: 'value',
                                labelColumn: 'display',
                            },
              useValueColumnAsValue: true,
              isMultiSelect: false,
              isInputDisabled: true,
              options: [
                                {
                                    value: 2003,
                                    display: 'Word 97 bis Word 2003 (.doc)',
                                },
                                {
                                    value: 2010,
                                    display:
                                        'Word 2010, Word 2013, Word 2016, Word 2019 (.docx)',
                                },
                            ],
              modelValue: _ctx.toEditData.format,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.toEditData.format) = $event))
            }, null, 8, ["label", "modelValue"])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["title", "cancelButtonText"]))
}