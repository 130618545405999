import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "w-full truncate relative outline-none text-md transition-colors pl-4 rounded-md bg-white-dark hover:bg-grey-lightest pt-6 pb-1.5" }
const _hoisted_2 = { class: "absolute top-1.5 left-0 pl-4 transition-colors w-full font-regular text-sm truncate select-none text-grey-darker" }
const _hoisted_3 = { class: "flex justify-between mb-1 mx-5" }
const _hoisted_4 = { class: "w-full flex justify-center" }
const _hoisted_5 = { class: "flex justify-between mb-1 mx-5" }
const _hoisted_6 = { class: "grid grid-cols-3 gap-1 border-t-2 border-grey-lightest mx-5 pt-2" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "grid grid-cols-3 gap-1 border-t-2 mx-5 pt-2 border-grey-lightest" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_arrow_left = _resolveComponent("icon-arrow-left")!
  const _component_icon_arrow_right = _resolveComponent("icon-arrow-right")!
  const _component_drop_down = _resolveComponent("drop-down")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_drop_down, {
      id: 'filterDropdown',
      ref: "dropdownElement",
      class: "w-full"
    }, {
      trigger: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('date')), 1),
          _createTextVNode(" " + _toDisplayString(_ctx.generatedDate.display), 1)
        ])
      ]),
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_icon_arrow_left, {
            class: "cursor-pointer",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectedYear--))
          }),
          _createVNode(_component_drop_down, {
            id: 'filterDropdown',
            ref: "dropdownElement",
            class: "w-full",
            dropdownStyle: { backgroundColor: 'white-dark' }
          }, {
            trigger: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.selectedYear), 1)
            ]),
            content: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_icon_arrow_left, {
                  class: "cursor-pointer",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.generateYearList(_ctx.displayYears[0] - 12)))
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.displayYears[0] + '-' + (_ctx.displayYears[11])) + " ", 1),
                _createVNode(_component_icon_arrow_right, {
                  class: "cursor-pointer",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.generateYearList(_ctx.displayYears[0] + 12)))
                })
              ]),
              _createElementVNode("div", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayYears, (year) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: year,
                    class: _normalizeClass(["flex items-center justify-center rounded-lg hover:bg-primary-light hover:text-white transition-all cursor-pointer p-1", `${_ctx.selectedYear == year ? 'bg-primary text-white' : ''}`]),
                    onClick: ($event: any) => (_ctx.selectYear(year))
                  }, _toDisplayString(year), 11, _hoisted_7))
                }), 128))
              ])
            ]),
            _: 1
          }, 512),
          _createVNode(_component_icon_arrow_right, {
            class: "cursor-pointer",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.selectedYear++))
          })
        ]),
        _createElementVNode("div", _hoisted_8, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.monthNames, (month, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: month,
              class: _normalizeClass(["xs:flex hidden items-center justify-center rounded-lg hover:bg-primary-light hover:text-white transition-all cursor-pointer p-1", `${_ctx.selectedMonth == index + 1 ? 'bg-primary text-white' : ''}`]),
              onClick: ($event: any) => (_ctx.selectMonth(index + 1))
            }, [
              _createElementVNode("p", null, _toDisplayString(month), 1)
            ], 10, _hoisted_9))
          }), 128)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.monthAbbreviations, (month, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: month,
              class: _normalizeClass(["xs:hidden flex items-center justify-center rounded-lg hover:bg-primary-light hover:text-white transition-all cursor-pointer p-1", `${_ctx.selectedMonth == index + 1 ? 'bg-primary text-white' : ''}`]),
              onClick: ($event: any) => (_ctx.selectMonth(index + 1))
            }, [
              _createElementVNode("p", null, _toDisplayString(month), 1)
            ], 10, _hoisted_10))
          }), 128))
        ])
      ]),
      _: 1
    }, 512)
  ]))
}