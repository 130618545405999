
import ConfirmRemovalModal from "../modals/ConfirmRemoveConnectionModal.vue";
import AddNormDocumentModal from "../modals/AddNormDocumentModal.vue";
import IconClose from "@/components/icons/IconCloseSideoverlay.vue";
import CustomButton from "@/components/inputs/customButton/CustomButton.vue";
import IconCheck from "@/components/icons/IconCheck.vue";
import IconCancel from "@/components/icons/IconClose.vue";
import IconFile from "@/components/icons/IconFile.vue";
import IconContainer from "@/components/helpers/iconContainer/IconContainer.vue";
import VersionContainer from "../../normAdministration/containers/VersionContainer.vue";
import VersionHeaderContainer from "../../normAdministration/containers/VersionHeaderContainer.vue";
import LoadingAnimation from "@/components/helpers/loadingAnimation/LoadingAnimation.vue";
import InputTextarea from "@/components/inputs/inputTextarea/InputTextarea.vue";
import { NormCustomerDocumentInterface } from "../../../store/norms/interfaces";
import { computed, defineComponent, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useHelpersStore } from "@/store/helpers";
import { useNormStore } from "@/store/norms";
import { cloneData } from "@/common/cloneData";
import { useI18n } from "vue-i18n";
import { ToastMessageType } from "@/components/helpers/theToastMessage/toast_message_helpers";

export default defineComponent({
    name: "customerSideContainer",
    props: {
        open: {
            type: Boolean,
            required: true,
        },
    },
    emits: ["close", "editNorm"],

    components: {
        IconClose,
        IconContainer,
        VersionContainer,
        LoadingAnimation,
        InputTextarea,
        IconCheck,
        IconCancel,
        CustomButton,
        IconFile,
        ConfirmRemovalModal,
        AddNormDocumentModal,
        VersionHeaderContainer,
    },
    setup() {
        const { t } = useI18n();
        const { setToastMessage, setLoadingId, removeLoadingId } =
            useHelpersStore();
        const { loadingIds } = storeToRefs(useHelpersStore());
        const { createNormNote, deleteNormDocument } = useNormStore();
        const textAreaElement = ref<HTMLElement>();
        const { norm, customerList, customerPagination } = storeToRefs(
            useNormStore()
        );
        const normNote = ref("");
        const isEditNote = ref(false);

        const toDeleteFile = ref();
        const isRemovalModalOpen = ref(false);
        const isUploadModalOpen = ref(false);

        const files = ref<NormCustomerDocumentInterface[]>([]);

        const isLoading = computed(() => {
            return loadingIds.value.find(
                (element) => element.name == "norm_details"
            );
        });
        const isCustomersLoading = computed(() => {
            return loadingIds.value.find(
                (element) => element.name == "customers_loading"
            );
        });

        function openUploadDocumentModal() {
            isUploadModalOpen.value = true;
        }
        function closeUploadDocumentModal() {
            isUploadModalOpen.value = false;
        }
        function editNormCustomerNote(): void {
            if (norm.value.notes) {
                normNote.value = cloneData(norm.value.notes);
            }
            isEditNote.value = true;
        }

        async function saveCustomerNote(): Promise<void> {
            const succeeded = await createNormNote(normNote.value);
            if (succeeded) {
                norm.value.notes = cloneData(normNote.value);
                setToastMessage(
                    ToastMessageType.SUCCESS,
                    t("create_norm_note_succeeded")
                );
            } else {
                setToastMessage(
                    ToastMessageType.ERROR,
                    t("create_norm_note_failed")
                );
            }
            isEditNote.value = false;
        }

        function openRemovalModal(fileName: number) {
            toDeleteFile.value = fileName;
            isRemovalModalOpen.value = true;
        }

        async function removeDocument() {
            setLoadingId("confirm-delete-button");
            const succeeded = await deleteNormDocument(toDeleteFile.value);

            if (succeeded) {
                norm.value.documents = norm.value.documents?.filter(
                    (document) => document.id != toDeleteFile.value
                );
                setToastMessage(
                    ToastMessageType.SUCCESS,
                    t("delete_document_succeeded")
                );
            } else {
                setToastMessage(
                    ToastMessageType.ERROR,
                    t("delete_document_failed")
                );
            }
            removeLoadingId("confirm-delete-button");
            isRemovalModalOpen.value = false;
        }

        return {
            isLoading,
            norm,
            customerList,
            isCustomersLoading,
            customerPagination,
            normNote,
            isEditNote,
            textAreaElement,
            saveCustomerNote,
            editNormCustomerNote,
            files,
            removeDocument,
            toDeleteFile,
            isRemovalModalOpen,
            openRemovalModal,
            openUploadDocumentModal,
            closeUploadDocumentModal,
            isUploadModalOpen,
        };
    },
});
