import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  id: "sideContainerHeader",
  class: "flex justify-between w-full items-center sticky -top-0 h-20 left-0 right-0 bg-white"
}
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = {
  id: "sideContainerContent",
  class: "w-full mt-10"
}
const _hoisted_4 = { class: "flex h-auto mb-4" }
const _hoisted_5 = { class: "text-grey-darker text-sm w-40 flex-shrink-0" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 1,
  class: "text-grey-darker text-sm"
}
const _hoisted_8 = { class: "flex h-auto mb-4" }
const _hoisted_9 = { class: "text-grey-darker text-sm w-40 flex-shrink-0" }
const _hoisted_10 = {
  key: 0,
  class: "text-grey-darker text-sm"
}
const _hoisted_11 = {
  key: 1,
  class: "text-grey-darker text-sm"
}
const _hoisted_12 = { class: "flex h-auto mb-4" }
const _hoisted_13 = { class: "text-grey-darker text-sm w-40 flex-shrink-0" }
const _hoisted_14 = {
  key: 0,
  class: "text-grey-darker text-sm"
}
const _hoisted_15 = {
  key: 1,
  class: "text-grey-darker text-sm"
}
const _hoisted_16 = { class: "flex h-auto mb-4" }
const _hoisted_17 = { class: "text-grey-darker text-sm w-40 flex-shrink-0" }
const _hoisted_18 = {
  key: 0,
  class: "text-grey-darker text-sm"
}
const _hoisted_19 = {
  key: 1,
  class: "text-grey-darker text-sm"
}
const _hoisted_20 = { class: "flex h-auto mb-4" }
const _hoisted_21 = { class: "text-grey-darker text-sm w-40 flex-shrink-0" }
const _hoisted_22 = {
  key: 0,
  class: "text-grey-darker text-sm"
}
const _hoisted_23 = {
  key: 1,
  class: "text-grey-darker text-sm"
}
const _hoisted_24 = { class: "flex h-auto mb-4" }
const _hoisted_25 = { class: "text-grey-darker text-sm w-40 flex-shrink-0" }
const _hoisted_26 = {
  key: 0,
  class: "text-grey-darker text-sm"
}
const _hoisted_27 = {
  key: 1,
  class: "text-grey-darker text-sm"
}
const _hoisted_28 = { class: "flex h-auto mb-4" }
const _hoisted_29 = { class: "text-grey-darker text-sm w-40 flex-shrink-0" }
const _hoisted_30 = {
  key: 0,
  class: "text-grey-darker text-sm"
}
const _hoisted_31 = {
  key: 1,
  class: "text-grey-darker text-sm"
}
const _hoisted_32 = { class: "pr-3 mb-2" }
const _hoisted_33 = { class: "text-grey-darker text-sm flex-shrink-0 mb-3" }
const _hoisted_34 = { key: 1 }
const _hoisted_35 = { class: "flex justify-end w-full mt-1" }
const _hoisted_36 = { class: "flex gap-3 transform items-center" }
const _hoisted_37 = { class: "text-sm flex-shrink-0 font-semibold" }
const _hoisted_38 = {
  key: 0,
  class: "absolute top-0 bg-white w-full h-full"
}
const _hoisted_39 = { class: "h-1/2 w-full flex items-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_close = _resolveComponent("icon-close")!
  const _component_icon_container = _resolveComponent("icon-container")!
  const _component_input_textarea = _resolveComponent("input-textarea")!
  const _component_icon_cancel = _resolveComponent("icon-cancel")!
  const _component_icon_check = _resolveComponent("icon-check")!
  const _component_icon_file = _resolveComponent("icon-file")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_version_header_container = _resolveComponent("version-header-container")!
  const _component_version_container = _resolveComponent("version-container")!
  const _component_loading_animation = _resolveComponent("loading-animation")!
  const _component_confirm_removal_modal = _resolveComponent("confirm-removal-modal")!
  const _component_add_norm_document_modal = _resolveComponent("add-norm-document-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["absolute xl:relative top-0 right-0 h-full bg-white p-3 pt-0 pl-5 transform transition-all duration-300", 
            _ctx.open
                ? 'w-full md:w-1/2 overflow-y-auto shadow-dark'
                : 'w-0 -mr-8 overflow-hidden'
        ])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _cache[8] || (_cache[8] = _createElementVNode("img", {
          src: "/logo.png",
          class: "h-7 flex-shrink-0"
        }, null, -1)),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_icon_container, {
            id: 'closeIcon',
            class: "ml-2",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_icon_close)
            ]),
            _: 1
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("policy")), 1),
            (_ctx.norm && _ctx.norm.policies)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.norm.policies, (policy) => {
                    return (_openBlock(), _createElementBlock("p", {
                      key: policy.id,
                      class: "text-grey-darker text-sm"
                    }, _toDisplayString(policy.name), 1))
                  }), 128))
                ]))
              : (_openBlock(), _createElementBlock("p", _hoisted_7, "-"))
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t("type")), 1),
            (_ctx.norm && _ctx.norm.type_short_name)
              ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(_ctx.norm.type_short_name), 1))
              : (_openBlock(), _createElementBlock("p", _hoisted_11, "-"))
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.$t("releaseDate")), 1),
            (_ctx.norm && _ctx.norm.displayed_issue_date)
              ? (_openBlock(), _createElementBlock("p", _hoisted_14, _toDisplayString(_ctx.norm.displayed_issue_date), 1))
              : (_openBlock(), _createElementBlock("p", _hoisted_15, "-"))
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.$t("number")), 1),
            (_ctx.norm && _ctx.norm.number)
              ? (_openBlock(), _createElementBlock("p", _hoisted_18, _toDisplayString(_ctx.norm.number), 1))
              : (_openBlock(), _createElementBlock("p", _hoisted_19, "-"))
          ]),
          _createElementVNode("div", _hoisted_20, [
            _createElementVNode("p", _hoisted_21, _toDisplayString(_ctx.$t("title")), 1),
            (_ctx.norm && _ctx.norm.name)
              ? (_openBlock(), _createElementBlock("p", _hoisted_22, _toDisplayString(_ctx.norm.name), 1))
              : (_openBlock(), _createElementBlock("p", _hoisted_23, "-"))
          ]),
          _createElementVNode("div", _hoisted_24, [
            _createElementVNode("p", _hoisted_25, _toDisplayString(_ctx.$t("comment")), 1),
            (_ctx.norm && _ctx.norm.annotations)
              ? (_openBlock(), _createElementBlock("p", _hoisted_26, _toDisplayString(_ctx.norm.annotations), 1))
              : (_openBlock(), _createElementBlock("p", _hoisted_27, "-"))
          ]),
          _createElementVNode("div", _hoisted_28, [
            _createElementVNode("p", _hoisted_29, _toDisplayString(_ctx.$t("state")), 1),
            (_ctx.norm && _ctx.norm.status_name)
              ? (_openBlock(), _createElementBlock("p", _hoisted_30, _toDisplayString(_ctx.norm.status_name), 1))
              : (_openBlock(), _createElementBlock("p", _hoisted_31, "-"))
          ])
        ]),
        _createElementVNode("div", _hoisted_32, [
          _createElementVNode("p", _hoisted_33, _toDisplayString(_ctx.$t("notes")), 1),
          (!_ctx.isEditNote)
            ? (_openBlock(), _createElementBlock("p", {
                key: 0,
                class: "pl-4 pt-2 cursor-pointer text-grey-darker bg-white-dark bg-opacity-50 rounded-lg h-24",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.editNormCustomerNote()))
              }, _toDisplayString(_ctx.norm.notes && _ctx.norm.notes != ""
                            ? _ctx.norm.notes
                            : "Keine Bemerkung hinterlegt"), 1))
            : (_openBlock(), _createElementBlock("div", _hoisted_34, [
                _createVNode(_component_input_textarea, {
                  id: 'customer-norm-note',
                  name: "customer-norm-note",
                  ref: "textAreaElement",
                  hasMeta: false,
                  rows: 3,
                  modelValue: _ctx.normNote,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.normNote) = $event))
                }, null, 8, ["modelValue"]),
                _createElementVNode("div", _hoisted_35, [
                  _createVNode(_component_icon_container, {
                    id: 'closeIcon',
                    class: "ml-2",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isEditNote = false))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_icon_cancel, { color: 'red' })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_icon_container, {
                    id: 'closeIcon',
                    class: "ml-2",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.saveCustomerNote()))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_icon_check, { color: 'primary' })
                    ]),
                    _: 1
                  })
                ])
              ]))
        ]),
        _createVNode(_component_custom_button, {
          id: 'add_norm_document',
          class: "max-w-full",
          isPlainButton: true,
          onClick: _ctx.openUploadDocumentModal
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_36, [
              _createVNode(_component_icon_file, { color: 'primary' }),
              _createElementVNode("p", _hoisted_37, _toDisplayString(_ctx.$t("upload_document")), 1)
            ])
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_version_header_container),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.norm.documents, (file, index) => {
          return (_openBlock(), _createBlock(_component_version_container, {
            key: index,
            versionItem: file,
            retracted: _ctx.norm?.status_name == 'Zurückgezogen',
            date: _ctx.norm.displayed_issue_date,
            onDelete: ($event: any) => (_ctx.openRemovalModal(file.id))
          }, null, 8, ["versionItem", "retracted", "date", "onDelete"]))
        }), 128))
      ]),
      (_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
            _createElementVNode("div", _hoisted_39, [
              _createVNode(_component_loading_animation, {
                id: 'loadingAnimation',
                name: 'loadingAnimation',
                animationStyle: { radius: '12', animationColor: 'grey' }
              })
            ])
          ]))
        : _createCommentVNode("", true)
    ], 2),
    _createVNode(_component_confirm_removal_modal, {
      open: _ctx.isRemovalModalOpen,
      onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.isRemovalModalOpen = false)),
      onDelete: _cache[6] || (_cache[6] = ($event: any) => (_ctx.removeDocument()))
    }, null, 8, ["open"]),
    _createVNode(_component_add_norm_document_modal, {
      open: _ctx.isUploadModalOpen,
      onClose: _cache[7] || (_cache[7] = ($event: any) => (_ctx.isUploadModalOpen = false))
    }, null, 8, ["open"])
  ], 64))
}