
import { computed, defineComponent, onMounted, ref, withCtx } from "vue";
import ConfirmRemovalModal from "./ConfirmRemoveConnectionModal.vue";
import CustomButton from "@/components/inputs/customButton/CustomButton.vue";
import CenteredModal from "@/components/layout/modals/CenteredModal.vue";
import InputDefault from "@/components/inputs/inputDefault/InputDefault.vue";
import InputDate from "@/components/inputs/inputDate/InputDate.vue";
import VersionContainer from "../../normAdministration/containers/VersionContainer.vue";
import VersionHeaderContainer from "../../normAdministration/containers/VersionHeaderContainer.vue";
import IconUpload from "@/components/icons/IconCloudUpload.vue";
import IconClose from "@/components/icons/IconClose.vue";
import { storeToRefs } from "pinia";
import { useHelpersStore } from "@/store/helpers";
import { useNormStore } from "@/store/norms";
import { generateFormData } from "@/common/generate_form_data";
import { ToastMessageType } from "@/components/helpers/theToastMessage/toast_message_helpers";
import { useI18n } from "vue-i18n";

export default defineComponent({
    name: "addNormlistModal",
    components: {
        CenteredModal,
        CustomButton,
        InputDefault,
        VersionContainer,
        VersionHeaderContainer,
        InputDate,
        IconUpload,
        IconClose,
        ConfirmRemovalModal,
    },
    props: {
        open: {
            type: Boolean,
            required: true,
        },
    },
    emits: ["close"],
    setup(props, ctx) {
        const {
            editNorm,
            norm,
            normPagination,
            // customerList,
            uploadedDocument,
            uploadedDocumentDate,
        } = storeToRefs(useNormStore());
        const { loadingIds } = storeToRefs(useHelpersStore());
        const { setLoadingId, removeLoadingId, setToastMessage } =
            useHelpersStore();
        const {
            uploadNormDocument,
            fetchNormDocuments,
            deleteNormDocument,
            fetchNormList,
        } = useNormStore();
        const isLoading = computed(() => {
            return loadingIds.value.some((id) => id.id == "upload_document");
        });
        const fileInput = ref();
        const toDeleteDocument = ref<number>();
        const isDeleteModalOpen = ref(false);

        function openDeleteModal(documentId: number) {
            toDeleteDocument.value = documentId;
            isDeleteModalOpen.value = true;
        }

        function closeDeleteModal() {
            toDeleteDocument.value = -1;
            isDeleteModalOpen.value = false;
        }

        async function inputChangeEvent(event: Event): Promise<void> {
            const target = event.target as HTMLInputElement;
            const files: FileList | null = target.files;
            if (files) {
                uploadedDocument.value = files[0];
                editNorm.value.saving_location = files[0].name;
            }
        }

        async function uploadDocument() {
            setLoadingId("upload_document");
            const fd = generateFormData({}, false);
            if (uploadedDocumentDate.value) {
                fd.append("date", uploadedDocumentDate.value);
            }
            if (uploadedDocument.value) {
                fd.append("document", uploadedDocument.value);
            }
            const response = await uploadNormDocument(fd);
            normPagination.value.page = 1;
            fetchNormList();
            fetchNormDocuments();
            if (response) {
                uploadedDocument.value = null;
                uploadedDocumentDate.value = "";
                editNorm.value.saving_location = "";
                ctx.emit("close");
                setToastMessage(
                    ToastMessageType.SUCCESS,
                    t("upload_document_succeeded")
                );
            } else {
                setToastMessage(
                    ToastMessageType.ERROR,
                    t("upload_document_failed")
                );
            }
            removeLoadingId("upload_document");
        }
        const { t } = useI18n();
        async function removeDocument() {
            if (toDeleteDocument.value) {
                setLoadingId("confirm-delete-button");
                const succeeded = await deleteNormDocument(
                    toDeleteDocument.value
                );

                if (succeeded) {
                    editNorm.value.documents = editNorm.value.documents?.filter(
                        (document) => document.id != toDeleteDocument.value
                    );
                    setToastMessage(
                        ToastMessageType.SUCCESS,
                        t("delete_document_succeeded")
                    );
                } else {
                    setToastMessage(
                        ToastMessageType.ERROR,
                        t("delete_document_failed")
                    );
                }
                removeLoadingId("confirm-delete-button");
            }
            isDeleteModalOpen.value = false;
        }

        onMounted(() => {
            uploadedDocument.value = null;
            uploadedDocumentDate.value = "";
            editNorm.value.saving_location = "";
        });
        return {
            isLoading,
            editNorm,
            inputChangeEvent,
            openDeleteModal,
            closeDeleteModal,
            uploadedDocument,
            uploadedDocumentDate,
            fileInput,
            norm,
            uploadDocument,
            removeDocument,
            isDeleteModalOpen,
        };
    },
});
