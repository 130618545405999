
import { computed, defineComponent, onMounted, ref } from "vue";
import DropDown from "@/components/helpers/dropDown/DropDown.vue";
import IconArrowLeft from "@/components/icons/IconArrowLeft.vue"
import IconArrowRight from "@/components/icons/IconArrowRight.vue"

type CalendarLanguageType = "de" | "en";
export default defineComponent({
    components: { DropDown, IconArrowLeft, IconArrowRight },
    emits: ['update:modelValue'],
    props: {
        /**
         * CalendarLanguageType
         */
        language: {
            type: String,
            default: "de",
        },
        modelValue: {
            type: String,
            required: true,
        },
    },
    setup(props, ctx) {
        const selectedYear = ref(2000)
        const selectedMonth = ref(1)
        const monthNames = ref(getMonths("long"));
        const monthAbbreviations = ref(getMonths("short"));
        const displayYears = ref<number[]>([])

        const generatedDate = computed(() => {
            const date = {
                display: (selectedMonth.value < 10 ? ('0' + selectedMonth.value) : selectedMonth.value) + '.' + selectedYear.value,
                value: selectedYear.value + '-' + (selectedMonth.value < 10 ? ('0' + selectedMonth.value) : selectedMonth.value)
            }
            return date
        })

        function generateYearList(currentYear: number): void {

            displayYears.value = []
            for (let year: number = currentYear; year < currentYear + 12; year++) {
                displayYears.value.push(year)

            }
        }


        function getMonths(monthLength: "short" | "long"): string[] {
            const months: string[] = [];
            for (let month = 1; month <= 12; month++) {
                const abbreviation = new Date(1970, month, 0).toLocaleString(
                    props.language as CalendarLanguageType,
                    {
                        month: monthLength,
                    }
                );
                months.push(abbreviation);
            }
            return months;
        }

        function selectYear(year: number): void {
            selectedYear.value = year
            ctx.emit('update:modelValue', generatedDate.value.value)
        }

        function selectMonth(month: number): void {
            selectedMonth.value = month
            ctx.emit('update:modelValue', generatedDate.value.value)
        }

        onMounted(() => {
            const date = new Date();
            selectedYear.value = date.getFullYear();
            selectedMonth.value = date.getMonth() + 1;
            ctx.emit('update:modelValue', generatedDate.value.value)
            generateYearList(selectedYear.value)
        })
        return { monthNames, monthAbbreviations, selectedYear, selectedMonth, generatedDate, generateYearList, displayYears, selectYear, selectMonth }
    }
})
