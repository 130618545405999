import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "h-auto w-full" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "text-primary font-medium" }
const _hoisted_4 = { class: "mt-5 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-6 gap-y-1 justify-between" }
const _hoisted_5 = { class: "flex gap-3 w-full col-span-2" }
const _hoisted_6 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_input_date = _resolveComponent("input-date")!
  const _component_icon_close = _resolveComponent("icon-close")!
  const _component_icon_upload = _resolveComponent("icon-upload")!
  const _component_input_default = _resolveComponent("input-default")!
  const _component_version_header_container = _resolveComponent("version-header-container")!
  const _component_version_container = _resolveComponent("version-container")!
  const _component_confirm_removal_modal = _resolveComponent("confirm-removal-modal")!
  const _component_centered_modal = _resolveComponent("centered-modal")!

  return (_ctx.open)
    ? (_openBlock(), _createBlock(_component_centered_modal, {
        key: 0,
        onClose: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('close'))),
        title: _ctx.$t('upload_document'),
        cancelButtonText: _ctx.$t('cancel'),
        mainContainerId: 'customer_administration'
      }, {
        actionButton: _withCtx(() => [
          _createVNode(_component_custom_button, {
            id: 'upload_document',
            class: "max-w-full",
            onClick: _ctx.uploadDocument
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("upload")), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("documentation_data")), 1)
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_input_date, {
                id: 'date',
                name: 'date',
                modelValue: _ctx.uploadedDocumentDate,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.uploadedDocumentDate) = $event)),
                isRequired: true,
                hasMeta: true,
                title: _ctx.$t('document_version_date'),
                label: _ctx.$t('file_date')
              }, null, 8, ["modelValue", "title", "label"]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_input_default, {
                  id: 'document',
                  name: 'document',
                  modelValue: _ctx.editNorm.saving_location,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editNorm.saving_location) = $event)),
                  isInputDisabled: true,
                  isRequired: true,
                  class: "w-full",
                  label: _ctx.$t('saving_location'),
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.fileInput.click()))
                }, {
                  append: _withCtx(() => [
                    (_ctx.editNorm.saving_location)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: "hover:bg-grey-light rounded-md transition-all cursor-pointer",
                          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (
                                        (_ctx.editNorm.saving_location = undefined),
                                            (_ctx.uploadedDocument = null)
                                    ), ["stop"]))
                        }, [
                          _createVNode(_component_icon_close, { color: 'grey-dark' })
                        ]))
                      : (_openBlock(), _createBlock(_component_icon_upload, {
                          key: 1,
                          class: "cursor-pointer"
                        }))
                  ]),
                  _: 1
                }, 8, ["modelValue", "label"])
              ])
            ]),
            _createElementVNode("input", {
              id: "file",
              accept: "application/pdf",
              type: "file",
              ref: "fileInput",
              class: "hidden",
              onChange: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.inputChangeEvent && _ctx.inputChangeEvent(...args)))
            }, null, 544)
          ]),
          (_ctx.norm && _ctx.norm.documents && _ctx.norm.documents.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_version_header_container),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.norm.documents, (version, index) => {
                  return (_openBlock(), _createBlock(_component_version_container, {
                    key: version.id,
                    index: index,
                    versionItem: version,
                    retracted: _ctx.norm?.status_name == 'Zurückgezogen',
                    date: _ctx.norm?.displayed_issue_date,
                    onDelete: ($event: any) => (_ctx.openDeleteModal(version.id))
                  }, null, 8, ["index", "versionItem", "retracted", "date", "onDelete"]))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_confirm_removal_modal, {
            open: _ctx.isDeleteModalOpen,
            onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.isDeleteModalOpen = false)),
            onDelete: _cache[6] || (_cache[6] = ($event: any) => (_ctx.removeDocument()))
          }, null, 8, ["open"])
        ]),
        _: 1
      }, 8, ["title", "cancelButtonText"]))
    : _createCommentVNode("", true)
}